
export enum PurchaseItemType {
  gems = 'gems',
  chatFromShop = 'chatFromShop',
  chatFromChats = 'chatFromChats',
  chatFromCards = 'chatFromCards',
  pack = 'pack',
  packfromChats = 'packFromchats',
  unBan = 'unBan',
  trialPurchase = 'trailPurchase',
  offerModal = 'offerModal',
  offerBanner = 'offerBanner'
}
