/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AuthPayload } from '../enums/auth-payload.enum';
import { AuthPayloadData } from '../types/auth-payload-data.interface';
import { AnalyticsService } from './analytics/analytics.service';
import { UserDataService } from './comunication_services/userData.service';

@Injectable({
  providedIn: 'root'
})
export class IncomePayloadDataService {

  private _clickId: BehaviorSubject<string> = new BehaviorSubject(null);

  get clickId$(): Observable<string> {
    return this._clickId.asObservable();
  }

  set clickId(value: string) {
    this._clickId.next(value);
  }

  constructor(
    private _nativeHttp: HTTP,
    private _analyticsService: AnalyticsService,
    private _platform: Platform,
    private _userDataService: UserDataService
  ) { }

  getPayloadDataFromParams(params: Params) {

    if(params.nutakuVersion) {
      this._userDataService.setNutakuDisplayVersion(params.nutakuVersion);
    }
    const payloadData = this._platform.is('hybrid') ? this._getParamsFromMobile() : of(params);

    payloadData.pipe(take(1)).subscribe(res => {

      if(res.clickid){
        const clickId = res.clickid || '';
        const partnerId = res.partnerid || '';
        localStorage.setItem(AuthPayload.clickId, clickId);
        localStorage.setItem(AuthPayload.partnerId, partnerId);
        this._analyticsService.clickIdSet(clickId, partnerId );
      }

      if(res.landing_behavior){
        localStorage.setItem(AuthPayload.landingBehavior, res.landing_behavior);
        this._analyticsService.landingBehaviorSet(res.landing_behavior);
      }
      // trafficSource
      if(res.utm_source){
        this.setUtmSource(res.utm_source, res.utm_medium, res.utm_campaign, res.utm_term, res.utm_content);
      }
      // Clickadu
      if(res.caduid) {
        localStorage.setItem(AuthPayload.caduid, res.caduid);
      }
      // Traffic Stars
      if(res.tscid) {
        localStorage.setItem(AuthPayload.tscid, res.tscid);
      }
      // Forced AB test for "as guest"
      if(res.abt) {
        localStorage.setItem(AuthPayload.forcedTest, res.abt);
      }
    });
  }

  private setUtmSource(utmSource, utmMedium, utmCampaign, utmTerm, utmContent) {
    localStorage.setItem(AuthPayload.utmSource, utmSource || '');
    localStorage.setItem(AuthPayload.utmMedium, utmMedium || '');
    localStorage.setItem(AuthPayload.utmCampaign, utmCampaign || '');
    localStorage.setItem(AuthPayload.utmTerm, utmTerm || '');
    localStorage.setItem(AuthPayload.utmContent, utmContent || '');
    this._analyticsService.utmSourceSet(utmSource, utmMedium, utmCampaign, utmTerm);
  }

  private _getParamsFromMobile(): Observable<any> {
    const offerId = document.querySelector('meta[name="offerId"]')?.getAttribute('content');
    const partnerid = document.querySelector('meta[name="pid"]')?.getAttribute('content');
    const utm_source = document.querySelector('meta[name="utm_source"]')?.getAttribute('content');
    const utm_medium = document.querySelector('meta[name="utm_medium"]')?.getAttribute('content');
    const utm_campaign = document.querySelector('meta[name="utm_campaign"]')?.getAttribute('content');
    const utm_term = document.querySelector('meta[name="utm_term"]')?.getAttribute('content');
    const utm_content = document.querySelector('meta[name="utm_content"]')?.getAttribute('content');
    const abt = document.querySelector('meta[name="abt"]')?.getAttribute('content');
    const clickId = document.querySelector('meta[name="clickid"]')?.getAttribute('content');
    const caduid = document.querySelector('meta[name="caduid"]')?.getAttribute('content');
    const tscid = document.querySelector('meta[name="tscid"]')?.getAttribute('content');

    if(clickId) {
      this.clickId = clickId;
    } else {
      this._affaiseCall(offerId, partnerid);
    }
    return this.clickId$.pipe(
      filter(value => value !== null),
      map(clickid => ({
        partnerid,
        clickid,
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        abt,
        caduid,
        tscid
        }))
    );
  }

  private _affaiseCall(offerId: string, pid: string): void {
    if(!offerId || !pid){
      this.clickId = '';
      return;
    }
    const url = `https://tsartech.g2afse.com/click?pid=${pid}&offer_id=${offerId}`;
    this._nativeHttp.get(url, {}, {}).then(result => {
      const resultUrl = result?.url;
      try {
        const clickId = new URL(resultUrl).searchParams.get('clickid') || '';
        this.clickId = clickId;
      } catch (_) {
        this.clickId = `NOT_REACHED ${resultUrl}`;
      }
    }, err => {
      console.log('G2afse error', err);
      this.clickId = `NOT_REACHED: server error`;
    });
  }


  public getAndRemovePayloadData(): AuthPayloadData {
    const clickId = localStorage.getItem(AuthPayload.clickId);
    const partnerId = localStorage.getItem(AuthPayload.partnerId);
    const trafficSource = localStorage.getItem(AuthPayload.trafficSource);
    const forcedTest = localStorage.getItem(AuthPayload.forcedTest);
    const caduid = localStorage.getItem(AuthPayload.caduid);
    const tscid = localStorage.getItem(AuthPayload.tscid);
    const utmSource = localStorage.getItem(AuthPayload.utmSource);
    const utmContent = localStorage.getItem(AuthPayload.utmContent);
    const utmMedium = localStorage.getItem(AuthPayload.utmMedium);
    const utmCampaign = localStorage.getItem(AuthPayload.utmCampaign);
    const utmTerm = localStorage.getItem(AuthPayload.utmTerm);
    localStorage.removeItem(AuthPayload.clickId);
    localStorage.removeItem(AuthPayload.partnerId);
    // localStorage.removeItem(AuthPayload.trafficSource); This param will need in promo ad. TODO refactor with BE to use it in only one way
    return {
      clickId: clickId || '',
      partnerId: partnerId || '',
      trafficSource: trafficSource || '',
      forcedTest: forcedTest || '',
      caduid: caduid || '',
      tscid: tscid || '',
      utmSource: utmSource || '',
      utmContent: utmContent || '',
      utmMedium: utmMedium || '',
      utmCampaign: utmCampaign || '',
      utmTerm: utmTerm || ''
    };
  }
}
