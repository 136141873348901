import { OffersModule } from './../offers/offers.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackChatsBonusComponent } from './pack-chats-bonus.component';
import { SwiperModule } from 'swiper/angular';
import { PaymentsModule } from '../payments/payments.module';



@NgModule({
  declarations: [
    PackChatsBonusComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    OffersModule
  ],
  exports: [
    PackChatsBonusComponent
  ]
})
export class PackChatsBonusModule { }
