<ng-container>
  <div class="modal">
    <div class="modal-head">Warning</div>
    <div class="modal-body">
      <div class="confirm-text text-center">Sorry, we have maintenance works now. Try again in 1 minute.</div>

      <div class="buttons">
        <button target="_blank"
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="close()"
        >Close</button>
      </div>
    </div>


  </div>
  <div class="modal-overlay" id="modal-overlay"></div>
</ng-container>
