import { environment } from 'src/environments/environment';
import { TutorialService } from './../../services/comunication_services/tutorial.service';
import { SoundsService } from './../../services/sounds.service';
import { UiStatesEnum } from './../../enums/ui-states.enum';
import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { NavHelper } from '../../helpers/nav.helper';
import { UiStateService } from '../../services/comunication_services/uiStates.service';
import { UiState } from '../../types/UiState';
import { UiStates } from '../../enums/uiStates';
import { SoundsEnum } from '../../enums/sounds.enum';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	public state: UiState = null;
  public uiStatesEnum = UiStatesEnum;
  public stateEnum = UiStates;
  environment = environment;
  tutorialStepsThatAvoidBackAndProfileButtons = [1,2,6,8,10];
	constructor(
		private _navHelper: NavHelper,
		private _uiStateService: UiStateService,
		private cdr: ChangeDetectorRef,
    private soundsService: SoundsService,
    private _tutorialService: TutorialService
	) {}

  ngOnInit() {
    this._uiStateService.updateStateData.subscribe(async (res: UiState) => {
			this.state = res;
			this.cdr.detectChanges();
		});
  }

	public goToShop(): void {
		this._navHelper.goToShopGems();
	}

  playPing1() {
    this.soundsService.play(SoundsEnum.coins);
  }

	public back(): void {
		if (this.state[UiStatesEnum.state] === 'chat') {
      if(this.tutorialStepsThatAvoidBackAndProfileButtons.indexOf(this._tutorialService.currentStep) > 0 ) {
        return;
      }
			this._navHelper.goToMain();
		} else if (this.state[UiStatesEnum.state] === 'album') {
			this._navHelper.goToGallery();
		} else if (this.state[UiStatesEnum.state] === 'profile') {
      this._navHelper.goToChat(this.state[UiStatesEnum.chatId]);
    }
	}

  public gotoProfile(chatId: string) {
    if(this.tutorialStepsThatAvoidBackAndProfileButtons.indexOf(this._tutorialService.currentStep) > 0 ) {
      return;
    }

    this._navHelper.goToProfile(chatId);
  }
}
