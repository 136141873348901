import { BuildVersion, EnvironmentModel } from './environment-model.interface';

export const environment: EnvironmentModel = {
  appId: 'com.fgf.getmynudes',
  appName: 'GetMyNudes',
  gameName: 'Get My Nudes',
  production: true,
  apiUrl: 'https://testapi.getmynudes.com',
  buildVersion: BuildVersion.erogames,
  webSiteUrl: 'https://getmynudes.com',
  rootPath: '',
  mode: 'test',
  showTncs: false,
  allowRegistrationForm: false,
  allowPaymentIframe: false,
  isPromoWidgetChat: false,
  splashScreenImgSrc: 'assets/imgx/splash-bg.png',
  firebaseConfig: {
    apiKey: 'AIzaSyCchXDxWly2G8Pf2pyWwU49SxxRo3rZ12A',
    authDomain: 'getmynudes-d4d83.firebaseapp.com',
    projectId: 'getmynudes-d4d83',
    storageBucket: 'getmynudes-d4d83.appspot.com',
    messagingSenderId: '215449707370',
    appId: '1:215449707370:web:1404c575674bc96385bb13',
    measurementId: 'G-3PN0MYE8RD'
  }
};
