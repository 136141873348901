<div class="modal-box signup-login rounded js-modal-box open display-up">

  <div class="flx content-login js-content-login display-up" style="">
<div class="flx-column">
<div class="js-login-head">
  <p class="fnt-large-2">Login</p>
  <p class="fnt-medium col-light-blue-gray">Login and play now</p>
</div>

<form class="flx-column js-login-modal-form">
  <div class="field js-field-input long light-blue flx">
      <input [(ngModel)]="emailField" type="email" name="email" maxlength="64" class="input light-blue rounded" placeholder="Email"><label class="plh">Email</label>
      <span class="icon-signup-error"></span>
  </div>
  <div class="field js-field-input long light-blue flx">
      <input [(ngModel)]="passwordField" type="password" name="password" minlength="6" maxlength="20" class="input light-blue rounded" placeholder="Password"><label class="plh">Password</label>
      <span class="icon-show-password js-show-password" style="display: inline;"></span>
      <span class="icon-signup-error"></span>
  </div>

  <div class="login-error" *ngIf="loginError">Login error. Please try again.</div>

  <button class="button filled primary fnt-large-2 txt-up ripple js-btn-submit" (click)="login()">
      <span class="fnt-medium txt-up js-btn-submit-initial" *ngIf="!loading">Login</span>
      <span class="fnt-medium txt-up loading-dots js-btn-submit-loading" *ngIf="loading">Loading</span>
  </button>

  <div class="flx-w-sp-b flx-a-e mar-t-1 fnt-small-3 forgot-pass">
      <div class="label-input col-lg-lg fnt-normal">
          <input type="checkbox" name="rememberMe" id="rememberMe" value="1" checked="" class="checkbox">
          <label for="rememberMe" class="col-light-blue-gray fnt-small-3">
              Keep me signed in                    </label>
      </div>

      <a target="_blank" href="https://www.nutaku.net/password/reset/" class="col-primary txt-u" data-trackid="NTK:TOUR:HOME:LINK nav header - login pop - forgot your pass">Forgot your password?</a>
  </div>


  <div class="mar-t-1 col-light-blue-gray">
      Don't have an account?                <a target="_blank" href="https://www.nutaku.net/signup/" class="js-signup" data-trackid="NTK:TOUR:HOME:LINK nav header - login pop - cta - 'signup'"><strong class="col-primary">Sign up</strong></a>
  </div>

  <input type="hidden" name="recaptcha" id="recaptchaLogin">
  <input type="hidden" name="pre_register_title_id" value="">
</form>
</div>
</div>

</div>
