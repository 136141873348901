export enum BuildVersion {
  erogames = 'erogames',
  legal = 'legal',
  default = 'default',
  nutaku = 'nutaku'
}

export interface EnvironmentModel {
  appId: string;
  appName: string;
  gameName: string;
  production: boolean;
  apiUrl: string;
  webSiteUrl: string;
  buildVersion: BuildVersion;
  mode: 'test' | 'prod';
  showTncs: boolean;
  allowRegistrationForm: boolean;
  allowPaymentIframe: boolean;
  isPromoWidgetChat: boolean;
  splashScreenImgSrc: string;
  rootPath: string;
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
}
