import { NutakuLoginModalComponent } from './nutaku-login-modal/nutaku-login-modal.component';
import { BannerSliderModule } from './../banner-slider/banner-slider.module';
import { MaintenanceModalComponent } from './maintenance-modal/maintenance-modal.component';
import { OfferModalComponent } from './offer-modal/offer-modal.component';
import { MatchModalComponent } from './match-modal/match-modal.component';
import { LottieModule } from 'ngx-lottie';
import { RewardsReceivedModalComponent } from './rewards-received-modal/rewards-received-modal.component';
import { UpdateVersionModalComponent } from './update-version-modal/update-version-modal.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnterNicknameModalComponent } from './enter-nickname-modal/enter-nickname-modal.component';
import { FormsModule } from '@angular/forms';
import { TrialPurchaseModalComponent } from './trial-purchase-modal/trial-purchase-modal.component';
import { ChatDetailsModalComponent } from './chat-details-modal/chat-details-modal.component';
import { IonicModule } from '@ionic/angular';
import { PackChatsBonusModule } from '../pack-chats-bonus/pack-chats-bonus.module';
import { OffersModule } from '../offers/offers.module';
import { PipesModule } from '../../pipes/pipes.module';



@NgModule({
  declarations: [
    UpdateVersionModalComponent,
    RewardsReceivedModalComponent,
    EnterNicknameModalComponent,
    MatchModalComponent,
    TrialPurchaseModalComponent,
    ChatDetailsModalComponent,
    OfferModalComponent,
    MaintenanceModalComponent,
    NutakuLoginModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LottieModule,
    IonicModule,
    PackChatsBonusModule,
    OffersModule,
    BannerSliderModule,
    PipesModule
  ],
  exports: [
    UpdateVersionModalComponent,
    RewardsReceivedModalComponent,
    EnterNicknameModalComponent,
    MatchModalComponent,
    TrialPurchaseModalComponent,
    ChatDetailsModalComponent,
    NutakuLoginModalComponent
  ]
})
export class MiscAlertsModule { }
