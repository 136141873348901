import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LocalStorageService } from './shared/services/comunication_services/localStorage.service';
import { JWTTokenService } from './shared/services/comunication_services/JWTToken.service';
import { AuthorizeGuard } from './shared/guards/autorize.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'launch'
    },
    {
        path: 'allChats',
        loadChildren: () => import('src/app/pages/chats/chats.module').then((m) => m.MainModule),
        canLoad: [AuthorizeGuard]
    },
    {
        path: 'launch',
        loadChildren: () => import('src/app/pages/launch/launch.module').then((m) => m.LaunchModule),
    },
    {
        path: 'gallery',
        loadChildren: () => import('src/app/pages/gallery/gallery.module').then((m) => m.GalleryModule),
        canLoad: [AuthorizeGuard],
    },
    {
        path: 'comics',
        loadChildren: () => import('src/app/pages/comics/comics.module').then((m) => m.ComicsModule),
    },
    {
        path: 'settings',
        loadChildren: () => import('src/app/pages/settings/settings.module').then((m) => m.SettingsModule),
        canLoad: [AuthorizeGuard],
    },
    {
        path: 'bonus',
        loadChildren: () => import('src/app/pages/bonus/bonus.module').then((m) => m.BonusModule),
        canLoad: [AuthorizeGuard],
    },
    {
        path: 'shop',
        loadChildren: () => import('src/app/pages/shop/shop.module').then((m) => m.ShopModule),
        canLoad: [AuthorizeGuard],
    },
    {
        path: 'swiping-chat-cards',
        loadChildren: () => import('src/app/pages/swiping-chat-cards/swiping-chat-cards.module').then((m) => m.SwipingChatCardsModule),
        canLoad: [AuthorizeGuard],
    },
    {
      path: 'profile',
      loadChildren: () => import('src/app/pages/profile/profile.module').then((m) => m.ProfileModule),
      data:{
        state: 'profile'
      },
      canLoad: [AuthorizeGuard],
    },
    {
      path: '**',
      redirectTo: 'allChats'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
      {
        enableTracing: false, errorHandler: err => {
          console.log('err hand', err);
        }
      },
      )],
    exports: [RouterModule],
    providers: [JWTTokenService, LocalStorageService],
})
export class AppRoutingModule { }
