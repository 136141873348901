import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsService } from '../API_services/settings.service';
import settings from '../../data/settings';

@Injectable({
    providedIn: 'root',
})

export class SettingsDataService {
    private data = {
        avatar: '',
        email: '',
        id: '',
        music: false,
        nickName: '',
        isNickNameSet: false,
        sound: false,
        quickMessaging: settings.quickMessaging,
        sendReportForm: false
    };

    private defaultData = {
        avatar: '',
        email: '',
        id: '',
        music: false,
        nickName: '',
        isNickNameSet: false,
        sound: false,
        quickMessaging: settings.quickMessaging,
        sendReportForm: false
    };
    public clientId ='';
    public updateSettingsData = new BehaviorSubject(this.data);
    public nicknameIsSet = false;

    constructor(
        private _settingsService: SettingsService,
    ) {
    }

    public changeData(fieldName: string, value: any): void {
        const defaultData = this.setNewData(this.data, fieldName, value);
        this.data = defaultData;
        this.updateSettingsData.next(defaultData);
        this._settingsService.updateSettings({
            music: this.data.music,
            sound: this.data.sound,
            nickname: this.data.nickName,
            quickMessaging: this.data.quickMessaging
        });
    }

    public setNewData(obj: any, field: string, value: any): any {
        const setPath = (object: any, path: any, value: any) => path
            .split('.')
            .reduce((o: any, p: any, i: any) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object);
        setPath(obj, field, value);
        return obj;
    }

    public changeAllObject(value: any): void {
        this.data = value;
        this.updateSettingsData.next(value);
    }

    public cleanData(): void{
        this.data = this.defaultData;
        this.updateSettingsData.next(this.data);
    }
}
