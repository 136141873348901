import { AuthStatus } from './../../enums/auth-status.enum';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import 'firebase/messaging';
import { filter, map, mergeMapTo, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { PushNotificationToken } from '../../types/push-notification-token';
import { AuthStateService } from '../auth/auth-state.service';
import { AnalyticsService } from '../analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private _host = environment.apiUrl;
  private _token: BehaviorSubject<PushNotificationToken> = new BehaviorSubject(null);
  constructor(
    private messaging: AngularFireMessaging,
    private _http: HttpClient,
    private _authStateService: AuthStateService,
    private _analyticsService: AnalyticsService
  ) {
    this._authStateService.authStatus$.pipe(
      filter(res => res === AuthStatus.authenticated),
      take(1),
      switchMap(() => this._token.asObservable()),
      filter(token => token !== null && token.token !== null)
    ).subscribe((token) => {
      this.registerToken(token);
    });
  }

  set token(token: PushNotificationToken) {
    this._token.next(token);
  }

  requestPermission(): Observable<boolean> {
    return this.messaging.requestPermission
      .pipe(
        mergeMapTo(this.messaging.tokenChanges),
        map(token => {
            // console.log('Permission granted! Save to the server!', token);
            this._analyticsService.webPushPermissionGranted();
            this.token = {
              token,
              platform: 'web'
            };
            return true;
          }
        )
      )
  }

  receiveMessage() {
    this.messaging.messages.subscribe((payload: any) => {
      console.log('messaging payload', payload);
      this._analyticsService.webPushReceived(payload.fcmOptions || {});
      window.location = payload.fcmOptions.link;
    });
  }

  registerToken(token: PushNotificationToken) {
    const requestUrl = `${this._host}/api/User/push-quest-complete`;
    this._http
      .put<any>(requestUrl, { ...token })
      .pipe(take(1))
      .subscribe();
  }
}
