import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MaintenanceModalComponent } from '../components/misc-alerts/maintenance-modal/maintenance-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ServerMaintenanceModalService {
  isShowed = false;

  constructor(private _modalCtrl: ModalController) {}

  showModal() {
    if(this.isShowed){
      return;
    }
    this.isShowed = true;
    this._modalCtrl
      .create({
        component: MaintenanceModalComponent,
        componentProps: {},
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
      })
      .then((modal: HTMLIonModalElement) => {
        modal.onDidDismiss().then(() => {
          this.isShowed = false;
        });
        return modal.present();
      });
  }
}
