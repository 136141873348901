import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UiStatesEnum } from '../../enums/ui-states.enum';
import { UiStates } from '../../enums/uiStates';
import { UiState } from '../../types/UiState';

@Injectable({
    providedIn: 'root',
})

export class UiStateService {
    public defaulState: UiState = {
        [UiStatesEnum.state]: UiStates.main,
        [UiStatesEnum.diamonds]: 0,
        [UiStatesEnum.avatarUrl]: '',
        [UiStatesEnum.characterName]: '',
        [UiStatesEnum.chatId]: '',
        [UiStatesEnum.isOffline]: false
    };
    private state: UiState;
    public updateStateData: BehaviorSubject<UiState>;
    private _showSwipingCards: BehaviorSubject<boolean>;

    get showSwipingCards(): Observable<boolean> {
      return this._showSwipingCards.asObservable();
    }

    constructor() {
      this.state = {...this.defaulState};
      this.updateStateData = new BehaviorSubject(this.state);
      this._showSwipingCards = new BehaviorSubject(null);
    }

    public changeData(fieldName: UiStatesEnum, value: any): void {
        this.state[fieldName.toString()] = value;
        this.updateStateData.next(this.state);
    }

    setShowSwipingCards(value: boolean) {
      this._showSwipingCards.next(value);
    }
}
