import {
    Injectable,
} from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpEvent,
    HttpResponse,
} from '@angular/common/http';
import {
    throwError,
} from 'rxjs';
import {
    catchError,
    map,
} from 'rxjs/operators';
import { JWTTokenService } from '../services/comunication_services/JWTToken.service';
import { ServerMaintenanceModalService } from '../services/server-maintenance-modal.service';

  @Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private _tokenService: JWTTokenService, private _serverMaintenanceModalService: ServerMaintenanceModalService) {}

    intercept(req: HttpRequest < any >, next: HttpHandler) {
        const token = this._tokenService.getToken();
        console.log('interceipt', req.url);
        req = req.clone({
            url: req.url,
            setHeaders: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Authorization: `Bearer ${token}`,
            },
        });
        return next.handle(req).pipe(
            map((event: HttpEvent < any >) => event),
            // catchError((error: HttpErrorResponse) => throwError(error)),
            catchError((error: HttpErrorResponse) => {
              if (error?.error?.Status === 'serverMaintenance.') {
                this._serverMaintenanceModalService.showModal();
              }
              return throwError(error);
            }),
        );
    }
}
