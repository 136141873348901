/* eslint-disable max-len */
import { PaymentAnimationStates } from 'src/app/shared/enums/payment-animation-states';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import {
  tap,
  map,
  catchError,
  switchMap,
  retry,
  concatMap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics/analytics.service';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { PaymentsStateService } from './payments-state.service';
import { PaymentResponse } from '../../types/payment-response.interface';
import { ShopService } from '../API_services/shop.service';
import { PurchaseData } from '../../types/purchase-data.interface';
import { PurchasePackData } from '../../types/purchase-pack-data-interface';
import { PaymentResponeStatus } from '../../enums/payment-respone-status.enum';
import { AuthErogamesService } from '../auth/auth-erogames.service.ts.service';
import { ModalController } from '@ionic/angular';
import { ErogamesPurchaseConfirmComponent } from '../../components/payments/erogames-modals/erogames-purchase-confirm/erogames-purchase-confirm.component';
import { ErogamesNotEnoughMoneyPopupComponent } from '../../components/payments/erogames-modals/erogames-not-enough-money-popup/erogames-not-enough-money-popup.component';
import { PurchaseItemType } from '../../enums/purchase-item-type.enum';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Injectable({
  providedIn: 'root',
})
export class ErogamesPaymentService {
  private _host: string = environment.apiUrl;
  constructor(
    private _http: HttpClient,
    private _analyticsService: AnalyticsService,
    private _tokenService: JWTTokenService,
    private _paymentsStateService: PaymentsStateService,
    private _shopService: ShopService,
    private _authErogamesService: AuthErogamesService,
    private _modalCtrl: ModalController
  ) {}

  private _getToken(): Observable<string> {
    return of(this._tokenService.erogamesToken);
  }

  private _afterPurchase(
    source: PurchaseItemType,
    productId: string = null,
    chatPackage: any = null,
    chatId = ''
  ) {
    const requestUrl = `${this._host}/api/Shop/buy`;
    const paymentMethod = BuildVersion.erogames;

    return of({
      productId,
      chatPackage,
      paymentMethod,
      chatId,
      source,
    }).pipe(
      switchMap((params) =>
        this._getToken().pipe(map((token) => ({ ...params, token })))
      ),
      concatMap((params) =>
        this._http.post<PaymentResponse>(requestUrl, params)
      ),
      switchMap((res) => {
        // Check if unauthorised - refreshToken and try again
        if (res.payment.status === PaymentResponeStatus.unauthorized) {
          this._analyticsService.notAuthorizedInPurchase(productId, source);
          return this._authErogamesService.erogamesRefreshToken().pipe(
            map((r) => {
              throw new Error('Not authorized');
            })
          );
        } else {
          return of(res);
        }
      }),
      retry(2),
      tap((res) => {
        const idForAnalytic = productId || 'pack';
        if (res.payment.status === PaymentResponeStatus.insufficientFunds) {
          this._showNoFundsModal();
          this._analyticsService.notEnoughFunds(
            idForAnalytic,
            res?.payment?.cost,
            res?.payment?.productId
          );
        }

        if (
          res.payment.status === PaymentResponeStatus.completed ||
          res.payment.status === PaymentResponeStatus.success
        ) {
          this._analyticsService.payment(
            res?.payment.platform,
            idForAnalytic,
            res.payment.cost,
            res?.payment?.productId,
            res?.payment?.lotType,
            res?.payment?.source,
            res?.payment?.meta,
            res?.payment?.offerType,
          );
        }
      })
    );
  }

  private _showNoFundsModal() {
    this._modalCtrl
      .create({
        component: ErogamesNotEnoughMoneyPopupComponent,
        swipeToClose: true,
        showBackdrop: true,
        cssClass: 'transparent-modal',
      })
      .then((res) => {
        res.present();
      });
  }

  async showConfirmationModal(cost) {
    const modal = await this._modalCtrl.create({
      component: ErogamesPurchaseConfirmComponent,
      componentProps: {
        cost,
      },
      swipeToClose: true,
      showBackdrop: true,
      cssClass: 'transparent-modal',
    });
    await modal.present();
    const res = await modal.onDidDismiss();
    return res;
  }

  purchase(dataSimple: PurchaseData = null, dataPack: PurchasePackData = null) {
    // Depends on simple item or pack item - different datas;
    const data = dataSimple || dataPack;

    return from(this.showConfirmationModal(data.erogamesCost)).pipe(
      switchMap((isSubmited) => {
        if (isSubmited.data) {
          this._analyticsService.confirmPurchaseModal(dataSimple);
          this._paymentsStateService.paymentState =
            PaymentAnimationStates.progress;
          return this._afterPurchase(
            dataSimple.source,
            dataSimple && dataSimple.id,
            dataPack && dataPack.pack,
            dataSimple?.chatId
          ).pipe(
            map((res) => {
              if (
                res.payment.status === PaymentResponeStatus.completed ||
                res.payment.status === PaymentResponeStatus.success
              ) {
                this._paymentsStateService.paymentState =
                  PaymentAnimationStates.success;
                this._shopService.saveDiamonds(res.coins);
                return true;
              } else {
                this._paymentsStateService.paymentState = null;
                return false;
              }
            })
          );
        } else {
          return of(false);
        }
      }),
      catchError((err) => {
        this._paymentsStateService.paymentState = PaymentAnimationStates.error;
        return of(false);
      })
    );
  }
}
