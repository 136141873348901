<app-chats-pack
  (click)="purchase()"
  *ngIf="
    offerData &&
    (offerData?.type === offerType.fiveChats ||
      offerData?.type === offerType.threeChats ||
      offerData?.type === offerType.oneChatAndGems ||
      offerData?.type === offerType.oneCheapChat)
  "
  [appearanceType]="appearanceType"
  [offerData]="offerData"
></app-chats-pack>
<!-- <app-exclusive-pack
  [appearanceType]="appearanceType"
  [offerData]="offerData"
></app-exclusive-pack> -->
<app-five-likes
  (click)="purchase()"
  (closeModal)="emitCloseModal()"
  *ngIf="offerData && offerData?.type === offerType.hiddenFiveChats"
  [purchased]="purchased"
  [appearanceType]="appearanceType"
  [offerData]="offerData"
></app-five-likes>
