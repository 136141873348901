<div class="pcb-container slider-modal see-five-likes" *ngIf="appearanceType === 'modal'">
  <div class="offer-banner">
    <div class="banner-top">
      <h2 *ngIf="!purchased">
        SEE<br />5 GIRLS <span class="bg-white color-orange">LIKED YOU</span>
      </h2>
      <h2 *ngIf="purchased">
        5 GIRLS <span class="bg-white color-orange">LIKED YOU</span>
      </h2>
      <div class="pcb-icons-box">
        <div
          class="pcb-icon"
          [ngClass]="'pcb-icon-' + (i + 1)"
          *ngFor="let chat of offerData.chats; let i = index"
        >
          <img [src]="chat.imageUrl" [ngClass]="{'unblurredd': purchased}" />
        </div>
      </div>
    </div>
    <div class="banner-bottom">
      <p *ngIf="!purchased">
        And Get Access<br /><span class="color-dark-grey">To Full CHats</span>
        With Them
      </p>

      <app-price-block [offerData]="offerData" *ngIf="!purchased"></app-price-block>
      <button class="btn btn-purchased" style="margin: 20px;width: calc(100% - 40px);" (click)="goToChats()" *ngIf="purchased">Go to Chats</button>
    </div>
  </div>
</div>
