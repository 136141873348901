import { PromoDatifyService } from '../promo-datify.service';
import { PromoStripchatService } from './../promo-stripchat.service';
import { TutorialService } from 'src/app/shared/services/comunication_services/tutorial.service';
/* eslint-disable max-len */
import { SoundsService } from './../sounds.service';
import { AnalyticsService } from './../analytics/analytics.service';
import { Injectable } from '@angular/core';
import { UserDataService } from '../comunication_services/userData.service';
import { ChatDataService } from '../comunication_services/chatData.service';
import settings from '../../data/settings';
import { environment } from 'src/environments/environment';
import { UiStateService } from '../comunication_services/uiStates.service';
import { NewMessageService } from '../comunication_services/new-message.service';
import { NextMessageEmitterService } from '../comunication_services/nextMessageEmitter.service';
import { UiStatesEnum } from '../../enums/ui-states.enum';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import { SoundsEnum } from '../../enums/sounds.enum';
import { SettingsDataService } from '../comunication_services/settingsData.sevice';
import { OpenNextMessage } from '../../types/open-next-message.interface';
import { ChatData, ChatType } from '../../types/chat-data.interface';
import { ApiNotificationsService } from '../notifications_services/api-notifications.service';
import { ChatMessageType } from '../../enums/chat-message-type.enum';
import { PromoBrand } from '../../enums/chat-promo-brand.enum';
import { ChatApiMethodsService } from './chat-api-methods.service';
import { ChatByIdResponse } from '../../types/chat-by-id-response.interface';
import { ChatStoreService } from './chat-store.service';
import { NextMessageAnswer } from '../../types/next-message.interface';
import { SetNicknameService } from '../set-nickname.service';

export interface NextMessagesPack {
  chatId: string;
  messages: OpenNextMessage[];
}
@Injectable({
  providedIn: 'root',
})
export class ChatService {
  quickMessaging = false;

  constructor(
    private _chatDataService: ChatDataService,
    private _userDataService: UserDataService,
    private _uiStateService: UiStateService,
    private _analyticsService: AnalyticsService,
    private _newMessageService: NewMessageService,
    private _nextMessageEmitterService: NextMessageEmitterService,
    private _soundService: SoundsService,
    private _settingsDataService: SettingsDataService,
    private _tutorialService: TutorialService,
    private _promoStripchatService: PromoStripchatService,
    private _promoDatingService: PromoDatifyService,
    // private _promoOnlyFansService: PromoOnlyfansService,
    private _apiNotificationsService: ApiNotificationsService,
    private _chatStoreService: ChatStoreService,
    private _chatApiMethodsService: ChatApiMethodsService,
    private _setNicknameService: SetNicknameService
  ) {
    this._settingsDataService.updateSettingsData.subscribe((res) => {
      this.quickMessaging = res.quickMessaging;
    });
  }

  public getChatById(chatId: string): Observable<ChatByIdResponse> {
    return this._chatApiMethodsService.getChatById(chatId).pipe(
      map(res => {
        this.saveMessages(res);
        return res;
      })
    );
  }

  public getAllChats(): Observable<boolean> {

    let isEmpty = false;

    return this._chatApiMethodsService.getAllChats()
      .pipe(
        switchMap((response) => {
          if(response.length === 0) {
            isEmpty = true;
          }
          if (
            environment.isPromoWidgetChat &&
            !this._tutorialService.tutorialModeOn
          ) {
            return this._promoStripchatService.addPromoWidgeToChat(
              response
            );
          }
          return of(response);
        }),
        // switchMap(response => {
        //   if(environment.isPromoWidgetChat && !this._tutorialService.tutorialModeOn){
        //     return this._promoOnlyFansService.addPromoWidgeToChat(response);
        //   }
        //   return of(response);
        // }),
        // switchMap((response) => {
        //   if (
        //     environment.isPromoWidgetChat &&
        //     !this._tutorialService.tutorialModeOn
        //   ) {
        //     return this._promoDatingService.addPromoWidgeToChat(response);
        //   }
        //   return of(response);
        // }),
        map((response) => {
          if(isEmpty){
            return this.addNoMessages(response);
          } else {
            return response;
          }
        }),
        map((response) => this.addDiscord(response)),
        tap((response: ChatData[]) => {
          this.saveChats(response);
        }),
        map((res) => true)
      );
  }

  public addDiscord(listOfChats) {
    listOfChats.push({
      characterAvatar: null,
      characterName: null,
      createdAt: '',
      id: '',
      dan: 0,
      messages: [],
      modifiedAt: '',
      packId: null,
      status: 'Open',
      users: null,
      feedbackFormShow: false,
      type: ChatType.discordLink,
      promoCompany: PromoBrand.discord,
      unbanPurchase: null
    } as ChatData);
    return listOfChats;
  }

  public addNoMessages(listOfChats) {
    listOfChats.push({
      characterAvatar: null,
      characterName: null,
      createdAt: null,
      id: null,
      dan: null,
      messages: [],
      modifiedAt: null,
      packId: null,
      status: null,
      users: null,
      feedbackFormShow: false,
      type: ChatType.noMessagesBadge,
      promoCompany: null,
      unbanPurchase: null
    } as ChatData);
    return listOfChats;
  }

  public zeroingOffline(chatId: string): Observable<boolean> {
    return this._chatApiMethodsService.zeroingOffline(chatId).pipe(
      map(res => {
        this.saveDiamonds(res.coins);
        this._uiStateService.changeData(UiStatesEnum.isOffline, false);
        this._newMessageService.toggleState(true);
        this._nextMessageEmitterService.changeData('unlocking');
        return true;
      })
    );
  }

  public buyAdditionalContent(chatId: string): Observable<boolean> {
    return this._chatApiMethodsService.buyAdditionalContent(chatId).pipe(
      map((res) => {
        if (res) {
          this.saveDiamonds(res.coins);
          return true;
        }
      })
    );
  }

  public sendFeedbackForm(chatId: string, data): Observable<boolean> {
    return this._chatApiMethodsService.sendFeedbackForm(chatId, data).pipe(
      map((res) => {
        if (res) {
          return true;
        }
      })
    );
  }

  public getNextMessages(chatId: string): Observable<boolean> {
    if (chatId.length < 15 || settings.goToId) {
      return of(false);
    }

    return this._chatStoreService.getNextMessagesFromPack(chatId).pipe(
      switchMap((response: any) => {
        let isAsset = false;



        if(!response[0]) {
          console.warn('No response data', response);
          return of(false);
        }

        this.saveBlockData(response[0]);

        if (response[0].isOffline) {
          this._analyticsService.chatIsOffline(chatId, response[0]?.nextMessage?.orderIdx);
          return of(isAsset);
        }

        const nextMessage = response[0].nextMessage;
        if (!nextMessage) {
          this._nextMessageEmitterService.changeDataTyping(false);
          return of(false);
        }

        if (nextMessage.type === ChatMessageType.model) {
          this._nextMessageEmitterService.changeDataTyping(true);
        } else {
          this._nextMessageEmitterService.changeDataTyping(false);
        }

        const isImage =
          response[response.length - 1].nextMessage.contentImageLink;
        const isVideo =
          response[response.length - 1].nextMessage.contentVideoLink;

        if (isVideo || isImage) {
          isAsset = true;
        }

        let responseTime =
          nextMessage.type === ChatMessageType.model
            ? settings.messageAwait + nextMessage.messageText?.length * 20
            : // nextMessage.messageText?.length * 50;
              // DISABLE MAN TYPINGS
              0;

        this._soundService.play(SoundsEnum.typing1, true);

        if (this.quickMessaging) {
          responseTime = 0;
        }

        if (responseTime > 3000) {
          responseTime = 3000;
        }

        return of(response).pipe(
          delay(responseTime || 0),
          map(() => {
            this._soundService.stop(SoundsEnum.typing1);
            if (!isAsset) {
              this._newMessageService.toggleState(true);
            }
            if (nextMessage.type !== ChatMessageType.model) {
              this._soundService.play(SoundsEnum.sentMessage2);
            } else {
              this._soundService.play(SoundsEnum.sentMessage1);
              this._nextMessageEmitterService.changeDataTyping(false);
            }
            this.saveNewMessages(response);
            // this._analyticsService.openMessage({
            //   messageId: nextMessage.id,
            //   chatId: nextMessage.chatId
            // });
            return isAsset;
          })
        );
      })
    );
  }

  public saveToGalery(
    chatId: string,
    url: string,
    contentType: string,
    messageId: string
  ): Observable<boolean> {
    return this._chatApiMethodsService.saveToGalery(chatId, url, contentType, messageId).pipe(
      map(() => true
        // this.saveDiamonds(res.coins);
      )
    );
  }

  private saveMessages(user: any) {
    this._chatDataService.changeAllObject(user);
  }

  private saveBlockData(data: OpenNextMessage) {
    this._chatDataService.changeBlockData(data);
  }

  private saveNewMessages(arr: any[]): void {
    for (let i = 0; i < arr.length; i++) {
      this._chatDataService.addMessage(arr[i].nextMessage);
    }
  }

  private saveChats(chats: ChatData[]) {
    this._userDataService.changeChatsData(chats);
  }

  public saveDiamonds(value: number): void {
    this._uiStateService.changeData(UiStatesEnum.diamonds, value);
  }

  public clickOnAnswer(answer: NextMessageAnswer, messageId: string, chatId: string) {

    if(answer.id === null){
      return;
    }

    if(!this._settingsDataService.nicknameIsSet) {
      if(answer.messageText === 'Now I will write my name, it will change in my profile!') {
        this._setNicknameService.setModal('On answer click');
      }
    }

    this._chatDataService.updateHeroLastMessageText(messageId, answer.messageText);
      this._chatApiMethodsService.chooseAnswer(chatId, answer.id).pipe(take(1)).subscribe(res => {
      this._chatDataService.updateHeroLastMessageEmoji(messageId, res.emoji);
      this._nextMessageEmitterService.changeData('clickOnAnswer');
    });
  }
}
