import { ShopService } from 'src/app/shared/services/API_services/shop.service';
import { take, switchMap } from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { UnsubscribeHelper } from '../../helpers/unsubscribe.helper';
import { PaymentsService } from '../../services/payments/payments.service';
import { PurchaseItemType } from '../../enums/purchase-item-type.enum';
import { OfferType } from '../../enums/offer-type.enum';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
})
export class OffersComponent implements OnInit, OnDestroy {
  private _subscribers = [];
  @Input() appearanceType: 'banner' | 'smallBanner' | 'modal';
  @Input() offerData: OfferData;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);
  @Input() purchased = false;
  offerType = OfferType;
  constructor(
    private _unsubscribeHelper: UnsubscribeHelper,
    private _paymentsService: PaymentsService,
    private _shopService: ShopService
  ) {}

  ngOnInit() {}

  purchase() {
    if(this.purchased){
      return;
    }
    if (this.offerData.pack) {
      this.purchasePack();
    } else {
      this.purchaseOffer();
    }
  }

  purchaseOffer() {
    this._subscribers.push(
      this._paymentsService
        .purchase({
          id: this.offerData.id,
          nutakuCost: this.offerData.nutakuCost,
          erogamesCost: this.offerData.erogamesCost,
          source: this.appearanceType === 'modal' ? PurchaseItemType.offerModal : PurchaseItemType.offerBanner,
          offerType: this.offerData.type
        })
        .pipe(
        ).subscribe((res) => {
          if (res) {
            this.purchased = true;
            if(this.offerData.type !== this.offerType.hiddenFiveChats){
              this.closeModal.emit(true);
            }
            this._subscribers.push(this._shopService.getPack().pipe(take(1)).subscribe());
          }
        })
    );
  }

  purchasePack() {
    this._subscribers.push(
      this._paymentsService
        .purchasePack(
          {
            pack: this.offerData.pack,
            erogamesCost: this.offerData.pack.erogamesCost,
          },
          this.appearanceType === 'smallBanner'
            ? PurchaseItemType.packfromChats
            : PurchaseItemType.pack
        )
        .pipe(
          take(1),
          switchMap(() => this._shopService.getPack().pipe(take(1)))
        ).subscribe(() => {
          this._shopService.getChats();
        })
    );
  }

  emitCloseModal() {
    this.closeModal.emit(true);
  }

  ngOnDestroy() {
    this._unsubscribeHelper.unsubscribe(this._subscribers);
  }
}
