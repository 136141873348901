<ng-container *ngIf="!hideBanner">
  <div class="ad-separator">Advertisement</div>
  <div class="pcb-container banner-slider-container" appEnterTheViewPort [showPromoOptions]="bannerAnalyticOptions">
    <div class="pcb-slider">
      <swiper [config]="swiperConfig" (slideChange)="onSlideChange($event)">
        <ng-template swiperSlide *ngFor="let banner of banners">
          <div class="flex-center" (click)="clickOnBanner(banner.img)">
            <a
              target="_blank"
              [href]="banner.url | addUtmContentAsUtmSource"
              ><img [src]="banner.img"
            /></a>
          </div>
        </ng-template>
      </swiper>
    </div>
  </div>
</ng-container>
