import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { UiStateService } from '../services/comunication_services/uiStates.service';

@Injectable({
    providedIn: 'root',
})
export class NavHelper {
    skipLocationChange = false;
    constructor(
        private router: Router,
        private _uiStateService: UiStateService
    ) {}

    getRouteEventUrl$(): Observable<string> {
      return this.router.events.pipe(
        map((event: any) => event.url)
        );
    }

    public goToMain(): void {
      this._uiStateService.showSwipingCards.pipe(filter(res => res !== null),take(1)).subscribe(res => {
        if( res === true){
          this.goToCards();
        } else {
          this.goToAllChats();
        }
      });

  }

    public goToAllChats(): void {
      this.router.navigateByUrl('allChats', { skipLocationChange: this.skipLocationChange });
    }

    public goToLaunch(): void {
        this.router.navigateByUrl('launch', { skipLocationChange: this.skipLocationChange });
    }

    public goToChat(id: string): void {
        this.router.navigateByUrl(`allChats/${id}`, { skipLocationChange: this.skipLocationChange });
    }

    public goToGallery(): void {
        this.router.navigateByUrl('gallery', { skipLocationChange: this.skipLocationChange });
    }

    public goToProfile(chatId: string): void {
      this.router.navigateByUrl(`profile/${chatId}`, { skipLocationChange: this.skipLocationChange });
    }

    public goToPersonGallery(id: string): void {
        this.router.navigateByUrl(`gallery/${id}`, { skipLocationChange: this.skipLocationChange });
    }

    public goToComics(): void {
        this.router.navigateByUrl('comics', { skipLocationChange: this.skipLocationChange });
    }

    public goToSettings(): void {
        this.router.navigateByUrl('settings', { skipLocationChange: this.skipLocationChange });
    }

    public goToBonusDailyReweards(): void {
        this.router.navigateByUrl('bonus/dailyRewards', { skipLocationChange: this.skipLocationChange });
    }

    public goToQuestLines(): void {
      this.router.navigateByUrl('bonus/questLine', { skipLocationChange: this.skipLocationChange });
  }

    public goToShopGems(): void {
        this.router.navigateByUrl('shop/gems', { skipLocationChange: this.skipLocationChange });
    }

    public goToCards(): void {
        this.router.navigateByUrl('swiping-chat-cards', { skipLocationChange: this.skipLocationChange });
    }

    public goToShopChats(): void {
        this.router.navigateByUrl('shop/secret', { skipLocationChange: this.skipLocationChange });
    }
}
