<ng-container *ngIf="data">
  <div class="profile-block">
    <div class="info-user">
      <div class="info-user__avatar">
        <img [attr.src]="this.data?.avatarLink" class="avatar-img" alt="ava" *ngIf="this.data?.avatarLink" />
        <div class="info-titles">
          <h2>{{this.data?.name}}</h2>
          <h6>Girl's rating: <b>{{this.rating.girl | number:'1.0-1'}}</b></h6>
          <h6>Story's rating: <b>{{this.rating.story | number:'1.0-1'}}</b></h6>
        </div>

      </div>
      <div class="info-user__content">

        <p class="name" [innerHTML]="this.data?.description">
        </p>

        <div style="margin-top: 10px;" *ngIf="data.links">
          <ng-container *ngFor="let link of data.links">
            <a *ngIf="link.linkType" class="promo-link" appEnterTheViewPort [showPromoOptions]="getShowLinkAnalyticOptions(link.linkType, 'Link in bio')" (click)="clickOnLinkInBio(link.linkType, 'Link in bio')" target="_blank" [href]="link.link | addUtmContentAsUtmSource">
              <img [src]="'/assets/img/promo-platforms/' + link.linkType?.toLowerCase() + '.png'"> <span>{{link.linkType}}</span>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <app-photo-gallery [albumData]="albumData"></app-photo-gallery>

  <app-banner-slider [bannerAnalyticOptions]="getShowLinkAnalyticOptions(promoBrand.bannerSlider, 'End of page')"></app-banner-slider>

  <ul class="message-list" style="margin-top: 20px;">
    <li class="message-list-item ng-star-inserted" >
      <a  appEnterTheViewPort [showPromoOptions]="getShowLinkAnalyticOptions(promoBrand.discord, 'End of page')" href="https://discord.gg/3UNBKCX2ZH" target="_blank" class="promo-click-area" (click)="clickOnDiscord()">
        CLick here
      </a
      >
      <div  class="avatar-box">
        <img  [src]="environment.rootPath +  '/assets/img/discord-blue.png'" alt="girl" class="avatar">
      </div>
      <div class="message-list-item__content display-flex">
        <div  class="message-list-item__head">
          <p >Share your feedback and get cool gifts in our <b >Discord</b></p>
        </div>
      </div>
    </li>
  </ul>


</ng-container>
