<div
  class="pcb-container slider-modal"
  *ngIf="appearanceType === 'modal'"
  [ngClass]="{
    'offer-5-chats': offerData.type === offerType.fiveChats,
    'offer-3-chats': offerData.type === offerType.threeChats,
    'one-chat-and-pack': offerData.type === offerType.oneChatAndGems,
    'one-cheap-chat': offerData.type === offerType.oneCheapChat
  }"
>
  <div class="offer-banner">
    <div class="banner-top">
      <h2 *ngIf="offerData.type === offerType.fiveChats">
        A BUNDLE FROM<br /><span>5 SECRET</span> CHATS
      </h2>
      <h2 *ngIf="offerData.type === offerType.threeChats">
        A BUNDLE FROM<br /><span>3 SECRET</span> CHATS
      </h2>
      <h2 *ngIf="offerData.type === offerType.oneChatAndGems">
        EXCLUSIVE PACK!
      </h2>
      <h2 *ngIf="offerData.type === offerType.oneCheapChat">
        1 MIN LIMITED OFFER!
      </h2>
      <div class="pcb-icons-box">
        <div
          class="pcb-icon"
          [ngClass]="'pcb-icon-' + (i + 1)"
          *ngFor="let chat of offerData.chats; let i = index"
        >
          <img [src]="chat.imageUrl" />
        </div>

        <img class="gems-scatter" [src]="environment.rootPath +  '/assets/img/gems-scatter.svg'" *ngIf="offerData.type === offerType.oneChatAndGems">
      </div>
      <div class="sale-block">
        <span class="sale">SALE</span>
        <span class="percents">{{ offerData.discount }}%</span>
        <span class="ring"></span>
      </div>
    </div>
    <div class="banner-bottom">
      <p
        class="girls-names-block"
        *ngIf="offerData.type === offerType.fiveChats || offerData.type === offerType.threeChats"
      >
        <span *ngFor="let chat of offerData.chats; let last = last">
          <a>{{ chat.name }}</a
          ><span *ngIf="!last">,</span>
        </span>
        are waiting for you!
      </p>
      <p
        class="girls-names-block"
        *ngIf="offerData.type === offerType.oneChatAndGems"
      >
        Exclusive Chat With <span>{{ offerData.chats[0].name }}</span> +
        <img class="img-inline-button" [src]="environment.rootPath +  '/assets/img/gem.png'" /><span
          class="mark-green"
          >{{ offerData.coins }}</span
        >
      </p>

      <p
        class="girls-names-block"
        *ngIf="offerData.type === offerType.oneCheapChat"
      >
      Chat With<br><span class="bold-name">{{ offerData.chats[0].name }}</span>
        <!-- <span class="subtitle">
          <span>This is only chance to pick it up with extra discount.</span>
        </span> -->
      </p>



      <app-price-block [offerData]="offerData"></app-price-block>
    </div>
  </div>
</div>

<div
  class="pcb-container offer-banner-container"
  *ngIf="appearanceType === 'banner' || appearanceType === 'smallBanner'"
  [ngClass]="{
    'slider-small': appearanceType === 'smallBanner',
    'offer-5-chats': offerData.type === offerType.fiveChats,
    'offer-3-chats': offerData.type === offerType.threeChats,
    'one-chat-and-pack': offerData.type === offerType.oneChatAndGems,
    'one-cheap-chat': offerData.type === offerType.oneCheapChat
  }"
>
  <div class="header-text-block">
    <h2 *ngIf="offerData.type === offerType.fiveChats">
      A BUNDLE FROM<br /><span>5 SECRET</span> CHATS
    </h2>
    <h2 *ngIf="offerData.type === offerType.threeChats">
      A BUNDLE FROM<br /><span>3 SECRET</span> CHATS
    </h2>
    <h2 *ngIf="offerData.type === offerType.oneChatAndGems">EXCLUSIVE PACK!</h2>
    <h2 *ngIf="offerData.type === offerType.oneCheapChat">EXCLUSIVE OFFER!</h2>
    <p class="girls-names-block" *ngIf="appearanceType !== 'smallBanner' && offerData.type !== offerType.oneCheapChat && offerData.type !== offerType.oneChatAndGems">
      <span *ngFor="let chat of offerData.chats; let last = last">
        <a>{{ chat.name }}</a
        ><span *ngIf="!last">,</span>
      </span>
      are waiting for you!
    </p>
    <p class="girls-names-block" *ngIf="appearanceType !== 'smallBanner' && (offerData.type === offerType.oneCheapChat || offerData.type === offerType.oneChatAndGems)">

     Chat with<br> <span *ngFor="let chat of offerData.chats; let last = last">
        <a>{{ chat.name }}</a
        ><span *ngIf="!last">, </span> <span style="margin-left: 10px;" class="mark-green font-black font-16" *ngIf="offerData.type === offerType.oneChatAndGems">+ <img class="img-inline-button" [src]="environment.rootPath +  '/assets/img/gem.png'" /> {{offerData.coins}}</span>
      </span>

    </p>
    <app-price-block [offerData]="offerData" *ngIf="offerData.type === offerType.oneCheapChat || offerData.type === offerType.oneChatAndGems"></app-price-block>
  </div>
  <div class="sale-block" *ngIf="appearanceType !== 'smallBanner'">
    <span class="sale">SALE</span>
    <span class="percents">20%</span>
    <span class="ring"></span>
  </div>
  <div class="pcb-icons-box">
    <div
      class="pcb-icon"
      [ngClass]="'pcb-icon-' + (i + 1)"
      *ngFor="let chat of offerData.chats; let i = index"
    >
      <img [src]="chat.imageUrl" />
    </div>
  </div>

  <app-price-block [offerData]="offerData" *ngIf="offerData.type !== offerType.oneCheapChat && offerData.type !== offerType.oneChatAndGems"></app-price-block>
</div>
