import { PromoBrand } from '../../enums/chat-promo-brand.enum';

export enum SwipingCardType {
  free = 'Free',
  secret = 'Secret',
  real = 'Real',
  soft = 'Soft',
  trial = 'Trial',
  fake ='Fake',
  promo = 'Promo'
}

export interface SwipingCard {
  id: string;
  cardSetId: string;
  type: SwipingCardType;
  name: string;
  description: string;
  chatId: string;
  imageUrl: string;
  chatLotId: string;
  cost: number;
  nutakuCost: number;
  erogamesCost: number;
  promoWidget?: string;
  promoCompany?: PromoBrand;
}

export interface GetSwipingCardsResponse {
  id: string;
  cards: SwipingCard[];
  timeout: number;
  skipCostSingle: number;
  skipCostPack: number;
  noMoreCards: boolean;
}

export interface ReactOnCardsRequest {
  // cardSetId: string;
  cardId: string;
  like: boolean;
}
