import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Component({
  selector: 'app-update-version-modal',
  templateUrl: './update-version-modal.component.html',
  styleUrls: ['./update-version-modal.component.scss'],
})
export class UpdateVersionModalComponent implements OnInit {
  @Input() updateFrom: string;
  @Input() updateTo: string;
  url = '';
  proxyLink = 'https://proxy.getmynudes.com/?redirectLink=';

  constructor(
    private _modalCtrl: ModalController,
    private _analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    switch(environment.buildVersion) {
      case BuildVersion.erogames:
        this.url = this.proxyLink + this._getEroGamesUrl();
        break;
      case BuildVersion.nutaku:
        // TODO this.url = this.proxyLink +
        break;
      case BuildVersion.default:
        this.url = this.proxyLink + this._getDefaultUrl();
        break;

    }
  }

  confirm() {
    // this._modalCtrl.dismiss(); // Don't close coz users must go and update version.
    this._analyticsService.clickOnUpdateApk(this.updateFrom, this.updateTo);
  }

  private _getEroGamesUrl() {
    const url = localStorage.getItem('eroHomeUrl');
    return url + '/en/games/get-my-nudes/';
  }

  private _getDefaultUrl() {
    // return 'https://cdn.sexting.club/release/android/getmynudes.apk';
    return 'https://getmynudes.com';
  }
}
