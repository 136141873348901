/* eslint-disable max-len */
import { PaymentAnimationStates } from 'src/app/shared/enums/payment-animation-states';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  map,
  switchMap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics/analytics.service';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { PaymentsStateService } from './payments-state.service';
import { PaymentResponse } from '../../types/payment-response.interface';
import { ShopService } from '../API_services/shop.service';
import { PurchaseData } from '../../types/purchase-data.interface';
import { PurchasePackData } from '../../types/purchase-pack-data-interface';
import { PaymentResponeStatus } from '../../enums/payment-respone-status.enum';
import { PurchaseItemType } from '../../enums/purchase-item-type.enum';

export interface NutakuPaymentResponse {
  nutakuId: string;
  isOk: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NutakuPaymentService {
  private _host: string = environment.apiUrl;
  constructor(
    private _http: HttpClient,
    private _analyticsService: AnalyticsService,
    private _tokenService: JWTTokenService,
    private _paymentsStateService: PaymentsStateService,
    private _shopService: ShopService
  ) {}

  private _afterPurchase(
    source: PurchaseItemType,
    nutakuId: string,
    chatPackage: any = null,
    chatId = ''
  ): Observable<boolean> {
    const requestUrl = `${this._host}/api/Shop/nutaku-buy`;
    const idForAnalytic = nutakuId || 'pack';

    return this._http.post<PaymentResponse>(requestUrl, {
      nutakuId,
      source,
      chatId,
      chatPackage
    }).pipe(
      map(res => {

        if (res.payment.status === PaymentResponeStatus.error || res.payment.status === PaymentResponeStatus.cancelled) {
          this._paymentsStateService.paymentState = PaymentAnimationStates.error;
          this._analyticsService.paymentError(
            res?.payment.platform,
            idForAnalytic,
            res?.payment?.productId
          );

          return false;
        }

        else if (res.payment.status === PaymentResponeStatus.success ) {
          this._paymentsStateService.paymentState = PaymentAnimationStates.success;
          this._analyticsService.payment(
            res?.payment.platform,
            idForAnalytic,
            res.payment.cost,
            res?.payment?.productId,
            res?.payment?.lotType,
            res?.payment?.source,
            res?.payment?.meta,
            res?.payment?.offerType
          );
          this._shopService.saveDiamonds(res.coins);
          return true;
        }

        else if (res.payment.status === PaymentResponeStatus.issued) {
          // Retry retry(2),
        }

        return false;
      })
    );

  }


  purchase(data: PurchaseData = null, dataPack: PurchasePackData = null): Observable<boolean> {

    return new Observable(observer => {

      this._paymentsStateService.paymentState = PaymentAnimationStates.progress;

      window.parent.postMessage(JSON.stringify({
        action: 'payment',
        data
      }), 'http://sbox-pf.nutaku.info');


      window.addEventListener('message', (res)  => {

        const result = JSON.parse(res?.data);
        console.log('Receive PM Payment from auth parent, data: ', result);

        if(result?.action === 'payment') {
          observer.next({
            nutakuId: result?.data?.nutakuId || '',
            isOk: result.isOk || false
          });
        }
      }, false);

    }).pipe(
      switchMap((res: NutakuPaymentResponse) => {
        console.log('Swithc map nutaku payment', res);
        if(res.isOk) {
          return this._afterPurchase(
            data.source,
            res.nutakuId
          );
        } else {
          this._paymentsStateService.paymentState = null;
          return of(false);
        }
      })
    );
  }

}
