import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ChatDataService } from '../../services/comunication_services/chatData.service';

@Component({
  selector: 'app-chat-rating-bar',
  templateUrl: './chat-rating-bar.component.html',
  styleUrls: ['./chat-rating-bar.component.scss'],
})
export class ChatRatingBarComponent implements OnInit {

  chatRating = 0;
  chatLevelProgress = 0;
  rating$: Observable<number>;
  level = null;
  levelUp = false;
  heartIcon: AnimationItem;

  saveToGalleryAnimationOptions: AnimationOptions = {
    path: `${environment.rootPath}/assets/animations/level-up/level-up.json`,
    loop: false,
  };

  heartRateAnimationOptins: AnimationOptions = {
    path: `${environment.rootPath}/assets/animations/heart-rate.json`,
    loop: false,
  };

  constructor(
    private _chatDataService: ChatDataService
  ) { }

  ngOnInit() {
    this.rating$ = this._chatDataService.updateBlockData.pipe(
      map((res => {

        if(this.level !== null && this.level < res.chatLevel) {
          this.showLevelUp();
        }

        if(this.chatRating !== null && this.chatRating < res?.chatRating) {
          this.showRatingUp();
        }




        this.level = res.chatLevel;


        this.chatRating = res.chatRating;
        this.chatLevelProgress = res.chatLevelProgress;
        return 100 - this.chatLevelProgress;
      }))
    );
  }

  heartAnimationCreate(el) {
    this.heartIcon = el;
  }

  showLevelUp() {
    this.levelUp = true;
    setTimeout(() => {
      this.levelUp = false;
    }, 2000);
  }

  showRatingUp() {
    this.heartIcon?.playSegments([0, 120], true);
  }

}
