import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-erogames-purchase-confirm',
  templateUrl: './erogames-purchase-confirm.component.html',
  styleUrls: ['./erogames-purchase-confirm.component.scss'],
})
export class ErogamesPurchaseConfirmComponent {
  @Input() cost: number = null;
  constructor(
    private _modalCtrl: ModalController
  ) {}

  confirm() {
    this._modalCtrl.dismiss(true);
  }

  cancel() {
    this._modalCtrl.dismiss(false);
  }
}
