import { IncomePayloadDataService } from './shared/services/income-payload-data.service';
import { NativePushNotificationService } from './shared/services/notifications_services/native-push-notification.service';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from './shared/services/comunication_services/loader.service';
import { NotificationService } from './shared/services/notifications_services/notification.service';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { HeaderFooterService } from './shared/services/comunication_services/headerFooter.service';
import { AnalyticsService } from './shared/services/analytics/analytics.service';
// import { NgxMetrikaService } from '@kolkov/ngx-metrika';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { SoundsService } from './shared/services/sounds.service';
import { AuthMethodsService } from './shared/services/auth/auth-methods.service';
import { WebPaymentService } from './shared/services/payments/web-payment.service';
import { CustomHeaderTextKey } from './shared/enums/custom-header-text-key.enum';
import { Observable, Subscription } from 'rxjs';
import { WebsocketSignalRService } from './shared/services/websocket-signalr.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { WebsocketCommandType } from './shared/enums/websocket-command-type.enum';
import { BuildVersion } from 'src/environments/environment-model.interface';
declare const gadgets;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AnalyticsService, NotificationService, LoaderService],
  // animations: [fadeAnimation]
})
export class AppComponent implements OnInit, OnDestroy {

  public isShowHeader = false;
  public isShowFooter = false;
  public isShowLoader = false;
  public installPrompt = null;
  public showBg = environment.buildVersion !== BuildVersion.legal;
  registrationCustomTextKey: CustomHeaderTextKey;
  registrationFormEnableClosing: boolean;
  _wsSubscription: Subscription;
  constructor(
    private _authMethodsService: AuthMethodsService,
    private _headerFooterService: HeaderFooterService,
    private _analyticsService: AnalyticsService,
    private _notificationService: NotificationService,
    private _loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    // private ym: NgxMetrikaService,
    private swUpdate: SwUpdate,
    private _nativePushNotificationService: NativePushNotificationService,
    private _soundsService: SoundsService,
    private _webPaymentService: WebPaymentService,
    private _incomePayloadDataService: IncomePayloadDataService,
    public websocketSignalRService: WebsocketSignalRService
  ) {

    // PWA update on changes
    this.swUpdate.available.subscribe(() => {
      if (confirm('A new version is available. Load it?')) {
        window.location.reload();
      }
    });

    this._headerFooterService.isShowHeader.subscribe((state) => {
      this.isShowFooter = state;
    });
    this._headerFooterService.isShowFooter.subscribe((state) => {
      this.isShowHeader = state;
    });
    this._loaderService.isLoader.subscribe((state) => {
      this.isShowLoader = state;
      if (state) {
        this.cdr.detectChanges();
      }
    });
    this._analyticsService.init();
  }

  ngOnInit() {

    this._notificationService.init();
    this._nativePushNotificationService.init();

    this.route.queryParams
      .subscribe(params => {
        if(environment.allowRegistrationForm){
          // Discord confirmation registration code
          if(params.code){
            this._authMethodsService.signUpWithDiscord(params.code).subscribe(
            res => {},
            error => {
              console.error(error);
            });
          }
          // Twitter confirmation token
          if(params.oauth_token && params.oauth_verifier){
            this._authMethodsService.signWithTwitter({
              oAuthToken: params.oauth_token,
              oAuthVerifier: params.oauth_verifier
            }).subscribe(
            res => {},
            error => {
              console.error(error);
            });
          }
        }
        this._incomePayloadDataService.getPayloadDataFromParams(params);

      });
    // this.ym.hit.emit();
    this._analyticsService.setPlatform();

    // Check if we have pending transaction
    // In Localstorage
    // And finish it
    this._webPaymentService.checkTransaction();
    this._wsSubscription = this.websocketSignalRService.init().pipe(
      filter(res => res === true),
      switchMap(() => this._instantWebsocketConnections()),
      take(1)
    ).subscribe();
  }

  getInstallPrompt(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
    });
  }

  askUserToInstallApp() {
    this.installPrompt.prompt();
  }

  private _instantWebsocketConnections(): Observable<boolean> {
    return this.websocketSignalRService.send(WebsocketCommandType.coins, {});
  }

  ngOnDestroy(): void {
    this._soundsService.destroy();
    this._wsSubscription?.unsubscribe();
  }
}
