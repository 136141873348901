import { AuthErogamesService } from './auth-erogames.service.ts.service';
import { Injectable } from '@angular/core';
import { from, Observable, of, pipe } from 'rxjs';
import { NavHelper } from '../../helpers/nav.helper';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../../enums/auth-status.enum';
import { AuthStateService } from './auth-state.service';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AuthMethodsService } from './auth-methods.service';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { AuthNutakuService } from './auth-nutaku.service';
// import ErogamesAuth from '../../plugins/erogames-auth';
// declare const eroAuth;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _tokenService: JWTTokenService,
    private _navHelper: NavHelper,
    private _authStateService: AuthStateService,
    private _authErogamesService: AuthErogamesService,
    private _authNutakuService: AuthNutakuService,
    private _authMethodsService: AuthMethodsService
  ) {

    if (environment.buildVersion === BuildVersion.erogames) {
      this._tokenService.erogamesToken = null;
    }
  }

  ///////////////

  private _changeRegistrationVisibility(isShow: boolean): void {
    if (environment.allowRegistrationForm) {

      if(isShow) {
        this._authStateService.showRegistrationForm({
          isShow: true,
          data: {
            placeWereCalled: 'Auth At Start',
            enableClosing: false
          }
        });
      } else {
        this._authStateService.hideRegistrationForm(false);
      }
    }
  }

  private _isAuthorisedByBuildVersion(): boolean {
    switch (environment.buildVersion) {
      case BuildVersion.erogames:
        return !!this._tokenService.erogamesToken;
      case BuildVersion.legal:
        return true;
      case BuildVersion.nutaku:
        return false;
      case BuildVersion.default:
        return !!(this._tokenService.getUserEmail() && !this._tokenService.isTokenExpired());
    }
  }

  private _showRegform() {
    this._navHelper.goToLaunch();
    if (localStorage.getItem('read_tncs')) {
      this._changeRegistrationVisibility(true);
    }
  }

  private _tryAsGuestABTestOrShowRegform() {
    this._authMethodsService.trySignAsGuest().pipe(
      catchError(() => {
        this._showRegform();
        return of(null);
      })
    ).subscribe();
  }

  private authenticationProcess(): Observable<boolean> {

    console.log('authenticationProcess', environment.buildVersion);

    switch (environment.buildVersion) {
      case BuildVersion.erogames:
        this._authErogamesService.erogamesTokenUpdateOnRefresh();
        break;
      case BuildVersion.legal:
        break;
      case BuildVersion.nutaku:
        this._authNutakuService.auth();
        // this._tryAsGuestABTestOrShowRegform();
        break;
      case BuildVersion.default:
        this._tryAsGuestABTestOrShowRegform();
        break;
    }

    return this._authStateService.authStatus$.pipe(
      filter(res => res === AuthStatus.authenticated),
      map(() => true),
      take(1)
    );
  }

  authorise(): Observable<any> {
    console.log('authorise');
    return this._authStateService.authStatus$.pipe(
      filter(res => res !== AuthStatus.inProgress),
      take(1),
      switchMap(res => {
        if(res === AuthStatus.authenticated) {
          console.log('authed');
          return from(this._authMethodsService.getUser()).pipe(
            map(() => true)
          );
        } else if(this._isAuthorisedByBuildVersion()){
          console.log('isauthorised');
          this._authStateService.authStatus = AuthStatus.authenticated;
          return from(this._authMethodsService.getUser()).pipe(
            map(() => true)
          );
        } else {
          console.log('inProgress');
          this._authStateService.authStatus = AuthStatus.inProgress;
          return this.authenticationProcess();
        }
      })
    );
  }
}
