import { UserDataService } from './../comunication_services/userData.service';
import { ErogamesPaymentService } from './../payments/erogames-payment.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from '../analytics/analytics.service';
import { PurchaseData } from '../../types/purchase-data.interface';
import { PurchasePackData } from '../../types/purchase-pack-data-interface';
import { WebPaymentService } from './web-payment.service';
import { PurchaseItemType } from '../../enums/purchase-item-type.enum';
import { BuildVersion } from 'src/environments/environment-model.interface';
import { NutakuPaymentService } from './nutaku-payment.service';
import { PaymentMethod } from '../../enums/payment-method.enum';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(
    private _analyticsService: AnalyticsService,
    private _erogamesPaymentService: ErogamesPaymentService,
    private _webPaymentService: WebPaymentService,
    private _nutakuPaymentService: NutakuPaymentService,
    private _userDataService: UserDataService
  ) {}

  purchase(data: PurchaseData): Observable<boolean> {
    this._analyticsService.clickOnPurchase(data.source, data.id, '', data.source, data.offerType);
    if (environment.buildVersion === BuildVersion.erogames) {
      return this._erogamesPaymentService.purchase(data, null);
    } else if(environment.buildVersion === BuildVersion.nutaku) {
      if(this._userDataService.nutakuDisplayVersion === 'pc') {
        return this._nutakuPaymentService.purchase(data);
      } else {
        return this._webPaymentService.purchase(data, PaymentMethod.nutakuSp);
      }
    } else {
      return this._webPaymentService.purchase(data);
    }
  }

  purchasePack(data: PurchasePackData, source: PurchaseItemType) {

    this._analyticsService.clickOnPurchase('Buy pack', data.erogamesCost.toString(), '', source);

    if (environment.buildVersion === BuildVersion.erogames) {
      return this._erogamesPaymentService.purchase({
        id: null,
        chatId: null,
        nutakuCost: null,
        erogamesCost: null,
        source
      }, data);
    } else if(environment.buildVersion === BuildVersion.nutaku) {
      if(this._userDataService.nutakuDisplayVersion === 'pc') {
        return this._nutakuPaymentService.purchase({
          id: null,
          chatId: null,
          nutakuCost: null,
          erogamesCost: null,
          source
        }, data);
      } else {
        return this._webPaymentService.purchasePack(data, PaymentMethod.nutakuSp);
      }

    } else {
      return this._webPaymentService.purchasePack(data);
    }
  }
}
