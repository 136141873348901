import { GetHostnamePipe } from './getHostname.pipe';
import { SafePipe } from './safe.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUtmContentAsUtmSourcePipe } from './add-utm-content-as-utm-source.pipe';
import { RemoveSeconds } from './removeSeconds';



@NgModule({
  declarations: [
    SafePipe,
    GetHostnamePipe,
    AddUtmContentAsUtmSourcePipe,
    RemoveSeconds
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafePipe,
    GetHostnamePipe,
    AddUtmContentAsUtmSourcePipe,
    RemoveSeconds
  ]
})
export class PipesModule { }
