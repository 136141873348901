import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { MiscAlertsModule } from './shared/components/misc-alerts/misc-alerts.module';
import { PaymentsModule } from './shared/components/payments/payments.module';
import { ProfileModule } from './pages/profile/profile.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FooterModule } from './shared/components/footer/footer.module';
import { HeaderModule } from './shared/components/header/header.module';
import { HttpConfigInterceptor } from './shared/interceptors/httpConfig.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistrationModule } from './shared/components/registration/registration.module';
import { Router, RouteReuseStrategy } from '@angular/router';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { LoaderModule } from './shared/components/loader/loader.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from 'angularx-social-login';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TutorialService } from './shared/services/comunication_services/tutorial.service';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
// import { GoogleAnalytics } from '@awesome-cordova-plugins/google-analytics/ngx';
// import * as SentryCapacitor from '@sentry/capacitor';
import * as Sentry from '@sentry/angular';
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';




// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function playerFactory() {
  return player;
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        RegistrationModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FooterModule,
        HeaderModule,
        IonicModule.forRoot(),
        LoaderModule,
        ReactiveFormsModule,
        SocialLoginModule,
        ProfileModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        MiscAlertsModule,
        // NgxMetrikaModule.forRoot({
        //   id: 86176143,
        //   defer: true,
        //   webvisor: false,
        //   clickmap: false,
        //   trackLinks: true,
        //   accurateTrackBounce: true
        // }),
        LottieModule.forRoot({ player: playerFactory }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        PaymentsModule
    ],
    providers: [
      // GoogleAnalytics,
      {
        provide: InAppBrowser,
        useClass: InAppBrowser
      },
      {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpConfigInterceptor,
          multi: true,
      },
      {
          provide: RouteReuseStrategy,
          useClass: IonicRouteStrategy
      },
      {
          provide: LocalNotifications,
          useClass: LocalNotifications
      },
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                '422350541625-cad89bssaqtb2eda36sqm6fqaj3b1u26.apps.googleusercontent.com'
              )
            }
          ]
        } as SocialAuthServiceConfig,
      },
      GooglePlus,
      HTTP,
      TutorialService,
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
