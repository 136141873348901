import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthMethodsService } from './auth-methods.service';
import { UserDataService } from '../comunication_services/userData.service';
import { ModalController } from '@ionic/angular';
import { NutakuLoginModalComponent } from '../../components/misc-alerts/nutaku-login-modal/nutaku-login-modal.component';
import { JWTTokenService } from '../comunication_services/JWTToken.service';


@Injectable({
  providedIn: 'root',
})
export class AuthNutakuService {

  constructor(
    private _authMethodsService: AuthMethodsService,
    private _userDataService: UserDataService,
    private _modalCtrl: ModalController,
    private _tokenService: JWTTokenService
  ) {}

  public auth() {

    // TODO Improve version checker.
    if( window.location.href.indexOf('localhost') > -1 ) {
      console.log('Nutaku APK version');
      this._apkLogin();
    } else if( this._userDataService.nutakuDisplayVersion === 'pc') {
      console.log('Nutaku PC version');
      this._onLoginPc();
    } else {
      console.log('Nutaku SP version');
      this._onLoginSp();
    }

  }

  // PC version of Nutaku
  private _onLoginPc() {
    window.parent.postMessage(JSON.stringify({action: 'auth', height: window.innerHeight}), 'http://sbox-pf.nutaku.info');
    window.addEventListener('message', (res)  => {
      try {
        const result = JSON.parse(res?.data);
        if(result?.action === 'auth') {
          this._authMethodsService.signWithNutaku(result.data).pipe(take(1)).subscribe();
        }
      } catch (error) {
        console.warn('Listener warn', error);
      }
    }, false);
  }

  // SP version of Nutaku
  private _onLoginSp() {
    this._authMethodsService.signWithNutakuSp().pipe(take(1)).subscribe();
  }

  // APK version of Nutaku
  private _apkLogin() {
    if(this._tokenService.getToken()) {
      this._authMethodsService.signWithNutakuApkTry().pipe(take(1)).subscribe(res => {}, err => {
        this._showLoginCustomModal();
      });
    } else {
      this._showLoginCustomModal();
    }
  }

  private _showLoginCustomModal() {
    this._modalCtrl.create({
      component: NutakuLoginModalComponent,
      componentProps: {},
      swipeToClose: true,
      showBackdrop: true,
      cssClass: 'transparent-modal',
    }).then(modal => modal.present());
  }
}
