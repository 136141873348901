import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { OfferType } from 'src/app/shared/enums/offer-type.enum';
import { NavHelper } from 'src/app/shared/helpers';
import { OfferData } from 'src/app/shared/types/offer-data.interface';


@Component({
  selector: 'app-five-likes',
  templateUrl: './five-likes.component.html',
  styleUrls: ['./five-likes.component.scss']
})
export class FiveLikesComponent implements OnInit {
  @Input() offerData: OfferData;
  @Input() appearanceType: 'modal' | 'banner' | 'smallBanner';
  @Input() purchased: boolean;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter(false);
  offerType = OfferType;
  constructor(
    private _navHelper: NavHelper
  ){}

  ngOnInit(){

  }

  goToChats() {
    this._navHelper.goToAllChats();
    this.closeModal.emit(true);
  }
}
