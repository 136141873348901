import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { OfferType } from 'src/app/shared/enums/offer-type.enum';

@Component({
  selector: 'app-offer-modal',
  templateUrl: './offer-modal.component.html',
  styleUrls: ['./offer-modal.component.scss'],
})
export class OfferModalComponent implements OnInit, OnDestroy {
  @Input() offerData: OfferData;
  @Input() purchased = false;
  loading = false;
  @ViewChild('searchElement') searchElement: ElementRef;
  public nickName = '';
  private _nicknameSub: Subscription = null;
  offerType = OfferType;
  environment = environment;
  constructor(
    private _analyticsService: AnalyticsService,
    private _modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this._analyticsService.showOffer(this.offerData);
  }

  close() {
    this._modalCtrl.dismiss();
  }

  ngOnDestroy() {
    this._nicknameSub?.unsubscribe();
  }

}
