import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { AnalyticsService } from 'src/app/shared/services/analytics/analytics.service';

@Component({
  selector: 'app-erogames-not-enough-money-popup',
  templateUrl: './erogames-not-enough-money-popup.component.html',
  styleUrls: ['./erogames-not-enough-money-popup.component.scss'],
})
export class ErogamesNotEnoughMoneyPopupComponent implements OnInit {
  constructor(
    private _platform: Platform,
    private _modalCtrl: ModalController,
    private _analyticsService: AnalyticsService
  ) { }

  ngOnInit() {}

  cancel() {
    this._modalCtrl.dismiss();
    this._analyticsService.notEnoughFundsDismiss();
  }

  confirm() {
    const erogoldUrl = localStorage.getItem('buyErogoldUrl');
    this._analyticsService.notEnoughFundsConfirm(erogoldUrl);
    if(erogoldUrl) {
      if(this._platform.is('hybrid')){
        Browser.open({ url: erogoldUrl });
      }else {
        window.open(erogoldUrl,'');
      }
    }
    this._modalCtrl.dismiss();
  }
}
