import { Injectable } from '@angular/core';
import { NavHelper } from '../helpers';
import { AnalyticsService } from './analytics/analytics.service';

export enum SplitTestCase {
  stella = 'StellaFirstChat|StellaFirstChat',
  // tinderShow = 'Tinder|Show',
  registrationSoft= 'Registration|Soft',
  onlyTinder = 'TinderVsChatList|OnlyTinder',
  onlyChats = 'TinderVsChatList|OnlyChatList',
}

@Injectable({
  providedIn: 'root',
})
export class SplitTestCasesService {
  splitTestCases: SplitTestCase[] = [];
  constructor(
    private _analyticsService: AnalyticsService,
  ) {}

  init(splitTests: string[]) {
    this._analyticsService.setSplitTests(splitTests);

    splitTests.forEach(testCaseRaw => {
      switch(testCaseRaw) {
        case SplitTestCase.stella:
          this.splitTestCases.push(SplitTestCase.stella);
          break;
        // case SplitTestCase.tinderShow:
        //   this.splitTestCases.push(SplitTestCase.tinderShow);
        //   break;
        case SplitTestCase.registrationSoft:
          this.splitTestCases.push(SplitTestCase.registrationSoft);
          break;
      }
    });

    splitTests.forEach(testCaseRaw => {
      this._cases(testCaseRaw);
    });
  }

  private _cases(testCaseRaw) {
    switch(testCaseRaw) {
      case SplitTestCase.stella:
        break;
      case SplitTestCase.registrationSoft:
        break;
    }
  }

  hasCase(testCase: SplitTestCase) {
    return !!this.splitTestCases.find(_testCase => _testCase === testCase);
  }
}
