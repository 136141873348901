import { AnalyticsService } from './../../services/analytics/analytics.service';
import { Component, Input, OnInit } from '@angular/core';
import { AuthStateService } from '../../services/auth/auth-state.service';
import { CustomHeaderTextKey } from '../../enums/custom-header-text-key.enum';
import { ShowRegistrationFormData } from '../../services/auth/show-registration-form-data';

export enum AuthViews {
  signUp,
  signIn,
  forgotPassword
}
@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  @Input() additionalData: ShowRegistrationFormData;
  customHeaderTextKeyEnum = CustomHeaderTextKey;
  public isSignIn = false;
  public isSignUp = true;
  public isForgotPassword = false;
  public authViews = AuthViews;
  activeView: AuthViews = AuthViews.signUp;
  constructor(
    private _authStateService: AuthStateService,
    private _analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this._analyticsService.showRegForm(this.additionalData);
  }

  showSingIn(): void{
    this.activeView = AuthViews.signIn;
  }

  showSingUp(){
    this.activeView = AuthViews.signUp;
  }

  showForgotPassword() {
    this.activeView = AuthViews.forgotPassword;
  }

  closeModal() {
    if(this.additionalData.data.enableClosing) {
      this._authStateService.hideRegistrationForm(false);
    }
  }
}
