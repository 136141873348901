<div class="price-block" >
  <div class="old-price">
    <span *ngIf="environment.buildVersion === buildVersion.default">${{ offerData.oldCost }}</span>
    <span *ngIf="environment.buildVersion === buildVersion.erogames">{{ offerData.oldErogamesCost }}</span>
    <span *ngIf="environment.buildVersion === buildVersion.nutaku">{{ offerData.oldNutakuCost }}</span>
    <span class="red-cross-line"></span>
  </div>
  <div class="new-price">
    <span *ngIf="environment.buildVersion === buildVersion.default">${{ offerData.cost }}</span>
    <span *ngIf="environment.buildVersion === buildVersion.erogames" class="btn-erogold-price" >{{ offerData.erogamesCost }}</span>
    <span *ngIf="environment.buildVersion === buildVersion.nutaku" class="btn-nutakugold-price" >{{ offerData.nutakuCost }}</span>
  </div>
</div>
