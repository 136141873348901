import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RewardsReceivedModalComponent } from '../../components/misc-alerts/rewards-received-modal/rewards-received-modal.component';
import { SoundsEnum } from '../../enums/sounds.enum';
import { ApiNotification } from '../../types/api-notification.interface';
import { SoundsService } from '../sounds.service';

@Injectable({
  providedIn: 'root'
})
export class ApiNotificationsService {

  constructor(
    private _soundsService: SoundsService,
    private _modalCtrl: ModalController
  ) { }

  // setNotifications(notifications: ApiNotification[]) {

  //   const pendingReward = notifications.filter(
  //     notification => notification.notificationType === 'PendingReward'
  //   )[0];
  //   if (pendingReward) {
  //     this._soundsService.play(SoundsEnum.bonus1);
  //     this._modalCtrl.create({
  //       component: RewardsReceivedModalComponent,
  //       componentProps: {
  //         dataForPopUp: pendingReward
  //       },
  //       swipeToClose: true,
  //       showBackdrop: true,
  //       cssClass: 'transparent-modal',
  //     }).then(modal => modal.present());
  //   }
  // }
}
