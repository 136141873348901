import { SplitTestCase, SplitTestCasesService } from 'src/app/shared/services/split-test-cases.service';
import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { switchMap, catchError, take, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../../enums/auth-status.enum';
import { SignMethod } from '../../enums/sign-method.enum';
import { NavHelper } from '../../helpers';
import { Clean } from '../../helpers/clean';
import { AuthResponse } from '../../types/auth-response.interface';
import { AuthPayloadData } from '../../types/auth-payload-data.interface';
import { TwitterSignInRequest } from '../../types/twitter-signIn-request.interface';
import { AnalyticsService } from '../analytics/analytics.service';
import { JWTTokenService } from '../comunication_services/JWTToken.service';
import { TutorialService } from '../comunication_services/tutorial.service';
import { AuthApiService } from './auth-api.service';
import { AuthStateService } from './auth-state.service';
import { IncomePayloadDataService } from '../income-payload-data.service';
import { SettingsDataService } from '../comunication_services/settingsData.sevice';
import { UserMyResponse } from '../../types/user-my-response.interface';
import { UiStateService } from '../comunication_services/uiStates.service';
import { CheckUpdatesService } from '../check-updates.service';
import { UiStatesEnum } from '../../enums/ui-states.enum';
import { BuildVersion } from 'src/environments/environment-model.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthMethodsService {
  private isHaveData = false;
  constructor(
    private _authApiService: AuthApiService,
    private _authStateService: AuthStateService,
    private _tokenService: JWTTokenService,
    private _analyticsService: AnalyticsService,
    private _clean: Clean,
    private _tutorialService: TutorialService,
    private _navHelper: NavHelper,
    private _incomePayloadDataService: IncomePayloadDataService,
    private _settingsDataService: SettingsDataService,
    private _splitTestCasesService: SplitTestCasesService,
    private _uiStateService: UiStateService,
    private _checkUpdatesService: CheckUpdatesService,
  ) {
    this._settingsDataService.updateSettingsData.subscribe((res) => {
      if (res.id) {
        this.isHaveData = true;
      } else {
        this.isHaveData = false;
      }
    });
  }


  public getUser(getAnyway: boolean = false): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      if (!this.isHaveData) {
        this._authApiService
          .getUser()
          .pipe(
            take(1)
          )
          .subscribe(
            (response) => {
              this.saveUserData(response);

              this._splitTestCasesService.init(response.splitTests);
              // this._setNicknameService.init(response.isNickNameSet); // Moved to chats

              this._checkUpdatesService.checkUpdates(response);
              this._authStateService.isGuest = response.isGuest;
              this._analyticsService.setIsGuest(response.isGuest);
              resolve(true);
            },
            (err) => {
              resolve(false);
            }
          );
      } else {
        resolve(true);
      }
    });
  }

  private saveUserData(data: UserMyResponse) {
    const noTinderCase = data?.splitTests.find(test => test === SplitTestCase.onlyChats) || false;
    this._uiStateService.setShowSwipingCards(!noTinderCase);
    this._settingsDataService.changeAllObject({
      avatar: data.avatar,
      email: data.email,
      id: data.id,
      music: data.music,
      nickName: data.nickName,
      isNickNameSet: data.isNickNameSet,
      sound: data.sound,
    });
    this._settingsDataService.clientId = data.id;
    this._analyticsService.setUserId(data.id);
    this._uiStateService.changeData(UiStatesEnum.diamonds, data.coins);
  }


  private _afterSignUp(response: AuthResponse & {method: SignMethod}): Observable<boolean> {
    console.log('_afterSignUp', response);

    if (!response.token || response.token === null) {
      return of(false);
    }
    this._tokenService.removeToken();
    this._clean.cleanSofData();
    this._tokenService.setToken(response.token);
    this._authStateService.authStatus = AuthStatus.authenticated;
    this._authStateService.isGuest =response.isGuest;
    this._analyticsService.authorize(response.method, response.isNewUser ? 'sign_up' : 'log_in');

    return from(this.getUser()).pipe(
      switchMap(() => {
        if (!response.tutorialIsPassed) {
          this._tutorialService.setTutorialDataAndRun({
            tutorialChatId: response.tutorialChatId,
            tutorialIsPassed: response.tutorialIsPassed,
            tutorialStep: response.tutorialStep,
          });
        } else {
          if (environment.buildVersion === BuildVersion.erogames) {
            this._navHelper.goToMain();
          }

          if (environment.buildVersion === BuildVersion.nutaku) {
            console.log('gotomain as nutaku');
            this._navHelper.goToMain();
          }

          if(response.firstChatId) {
            this._navHelper.goToChat(response.firstChatId);
          }
        }
        this._authStateService.hideRegistrationForm(true);
        return of(true);
      })
    );
  }

  signIn(
    email: string,
    password: string
  ): Observable<boolean> {
    const payload = this._getAndRemovePayloadData();
    const data = {
      email,
      password,
      payload,
    };
    return this._authApiService
      .signIn(data)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.email,
        }))
      );
  }
  nutakuSignIn(
    email: string,
    password: string
  ): Observable<boolean> {
    const payload = this._getAndRemovePayloadData();
    const data = {
      email,
      password,
      payload,
    };
    return this._authApiService
      .signWithNutakuApk(data)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.email,
        }))
      );
  }

  signUp(
    email: string,
    password: string,
    nickName: string
  ): Observable<boolean> {
    const payload = this._getAndRemovePayloadData();
    const data = {
      email,
      password,
      nickName,
      payload,
    };
    return this._authApiService
      .signUp(data)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.email,
        }))
      );
  }

  signUpWithGoogle(
    tokenId: string
  ): Observable<boolean> {
    const payload = this._getAndRemovePayloadData();
    const data = {
      token: tokenId,
      payload,
    };
    return this._authApiService
      .signUpWithGoogle(data)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.google,
        }))
      );
  }

  signUpWithDiscord(
    code: string
  ): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload = this._getAndRemovePayloadData();
    const data = {
      code,
      redirectUrl: window.location.origin,
      payload,
    };
    return this._authApiService
      .signUpWithDiscord(data)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.discord,
        })),
        catchError((error) => {
          this.logout();
          throw error;
        })
      );
  }

  signWithTwitter(
    data: TwitterSignInRequest
  ): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    return this._authApiService
      .signWithTwitter({
        ...data,
        payload,
      })
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.twitter,
        })),
        catchError((error) => {
          this.logout();
          throw error;
        })
      );
  }

  trySignAsGuest(): Observable<boolean> {
    this._authStateService.authStatus = AuthStatus.inProgress;
    const payload: AuthPayloadData = this._getAndRemovePayloadData();
    return this._authApiService
      .trySignupAsGuest(payload)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.asGuest,
        }))
      );
  }

  signWithErogames(): Observable<boolean> {
    const erogamesToken = this._tokenService.erogamesToken;
    const whiteLabel = document.referrer && new URL(document.referrer)?.hostname || null;
    return this._authApiService
      .signWithErogames(erogamesToken, whiteLabel)
      .pipe(
        switchMap((response: AuthResponse) => this._afterSignUp({
          ...response,
          method: SignMethod.erogames,
        }))
      );
  }

  signWithNutaku(response: AuthResponse): Observable<boolean> {
    return this._afterSignUp({...response, method: SignMethod.nutaku});
  }

  signWithNutakuSp(): Observable<boolean> {
    return this._authApiService.signWithNutakuSp().pipe(
      switchMap((response: AuthResponse) => this._afterSignUp({
        ...response,
        method: SignMethod.nutaku,
      }))
    );
  }

  signWithNutakuApkTry(): Observable<boolean> {
    return this._authApiService.signWithNutakuApkTry().pipe(
      switchMap((response: AuthResponse) => this._afterSignUp({
        ...response,
        method: SignMethod.nutaku,
      }))
    );
  }

  private _getAndRemovePayloadData(): AuthPayloadData {
    return this._incomePayloadDataService.getAndRemovePayloadData();
  }

  public logout(): void {
    this._authStateService.authStatus = AuthStatus.notAuthenticated;
    this._tokenService.removeToken();
    localStorage.clear();
    window.location.href = '/';
  }

  public wipeOut() {
    this._authApiService.wipeOut().subscribe((res) => {
      localStorage.clear();
      window.location.href = '/';
    });
  }
}
