import { PriceBlockComponent } from './templates/price-block/price-block.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatsPackComponent } from './templates/chats-pack/chats-pack.component';
import { ExclusivePackComponent } from './templates/exclusive-pack/exclusive-pack.component';
import { FiveLikesComponent } from './templates/five-likes/five-likes.component';
import { OffersComponent } from './offers.component';

@NgModule({
  declarations: [
    ChatsPackComponent,
    ExclusivePackComponent,
    FiveLikesComponent,
    OffersComponent,
    PriceBlockComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ChatsPackComponent,
    ExclusivePackComponent,
    FiveLikesComponent,
    OffersComponent,
    PriceBlockComponent
  ]
})
export class OffersModule {}
