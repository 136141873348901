<ng-container>
  <div class="modal">
    <div class="modal-head">Confirmation</div>
    <div class="modal-body">
      <div class="confirm-text">You are buying this item for <img src="./assets/img/erogold.svg">{{cost}}</div>

      <div class="buttons">
        <button
          class="btn btn-block btn-uppercase"
          type="submit"
          (click)="cancel()"
        >Cancel</button>

        <button
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="confirm()"
        >Confirm</button>
      </div>
    </div>


  </div>
</ng-container>
