<div class="game-bg" *ngIf="showBg">
  <div class="game-bg-left-side"></div>
  <div class="game-bg-right-side"></div>
</div>
<div class="wrapper">
  <div class="connection-problems-toast" [ngClass]="{'show': websocketSignalRService.connectionProblemToast$ | async}">Connection problems. Reconnecting... <span class="spinner"></span></div>
  <div class="attachment">
    <app-header *ngIf="isShowHeader"></app-header>
    <!-- <div [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
    </div> -->
    <router-outlet #o="outlet"></router-outlet>
    <app-footer *ngIf="isShowFooter" style="margin-top: auto"></app-footer>
    <app-loader *ngIf="isShowLoader"></app-loader>
  </div>
</div>
<app-payments></app-payments>
