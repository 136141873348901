import { Component, Input, OnInit } from '@angular/core';
import { OfferData } from 'src/app/shared/types/offer-data.interface';
import { environment } from 'src/environments/environment';
import { BuildVersion } from 'src/environments/environment-model.interface';


@Component({
  selector: 'app-price-block',
  templateUrl: './price-block.component.html',
  styleUrls: ['./price-block.component.scss']
})
export class PriceBlockComponent implements OnInit {

  @Input() offerData: OfferData;
  environment = environment;
  buildVersion = BuildVersion;
  constructor(){}

  ngOnInit(){

  }
}
