<ng-container>
  <div class="modal">
    <div class="modal-head">Warning</div>
    <div class="modal-body">
      <div class="confirm-text">You don't have enough erogold. Please top up your account.</div>

      <div class="buttons">
        <button
          class="btn btn-block btn-uppercase"
          type="submit"
          (click)="cancel()"
        >Not now</button>

        <button
          class="btn btn-primary btn-block btn-uppercase"
          type="submit"
          (click)="confirm()"
        >Buy erogold</button>
      </div>
    </div>
  </div>
</ng-container>
