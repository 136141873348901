import { ServerMaintenanceModalService } from './server-maintenance-modal.service';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  catchError,
  delay,
  take,
  retryWhen,
  takeWhile,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthStatus } from '../enums/auth-status.enum';
import { AuthStateService } from './auth/auth-state.service';
import { JWTTokenService } from './comunication_services/JWTToken.service';
import { WebsocketCommandType } from '../enums/websocket-command-type.enum';

export enum WebsocketConnectionType {
  send = 'send',
  invoke = 'invoke',
}

export interface WSInvokeResponse<T> {
  type: WebsocketCommandType;
  result: T;
  error?: {
    error: any;
    status: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class WebsocketSignalRService {
  private _connection;
  private _connected = false;
  private _connectionProblemToast: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get connectionProblemToast$(): Observable<boolean> {
    return this._connectionProblemToast.asObservable();
  }

  constructor(
    private _tokenService: JWTTokenService,
    private _authStateService: AuthStateService,
    private _serverMaintenanceModalService: ServerMaintenanceModalService,
  ) {}

  init(): Observable<boolean> {
    return this._authStateService.authStatus$.pipe(
      filter((status) => status === AuthStatus.authenticated),
      switchMap(() => {
        const token = this._tokenService.getToken();

        this._connection = new signalR.HubConnectionBuilder()
          .configureLogging(signalR.LogLevel.Information)
          .withUrl(environment.apiUrl + '/game', {
            accessTokenFactory: () => token
          })
          .withAutomaticReconnect()
          .build();

        this._connection.on('OnResponse', (message) => {
          // console.log('response', message);
        });

        return this._connect();
      })
    );
  }

  private _connect(): Observable<boolean> {
    return of(null).pipe(
      switchMap(() => {
        // console.log('Connection.state', this._connection.state);
        switch (this._connection.state) {
          case 'Disconnected':
            return from(this._connection.start()).pipe(
              map(() => {
                // console.log('WS CONNECTED');
                this._connected = true;
                return true;
              }),
              catchError((err) => {
                // console.error('Websocket connection error', err.toString());
                throw err;
              })
            );
          case 'Connecting':
            // console.log('Connecting');
            return of(false);
          case 'Reconnecting':
            // console.log('Reconnecting');
            return of(false);
          case 'Connected':
          default:
            // console.log('Connected');
            return of(true);
        }
      })
    );
  }

  send(type: WebsocketCommandType, command): Observable<any> {
    return this._connect().pipe(
      filter((res) => res === true),
      switchMap(() => from(this._connection.send('Send', { type, command })))
    );
  }

  invoke<ResultType>(
    type: WebsocketCommandType,
    command
  ): Observable<ResultType> {
    return this._connect().pipe(
      map((res) => {
        if (!res) {
          // eslint-disable-next-line no-throw-literal
          throw 'noConnection';
        }
        return res;
      }),
      retryWhen((errors) =>
        errors.pipe(
          takeWhile((error) => {
            if(this._connected) {
              this._connectionProblemToast.next(true);
            }
            // console.log('RETRY WHEN ERROR', error);
            return true;
          }),
          delay(1000)
        )
      ),
      switchMap(() =>
        from(this._connection.invoke('Execute', { type, command })).pipe(
          mergeMap((response: WSInvokeResponse<ResultType>) => {
            switch (response.type) {
              case WebsocketCommandType.error:
                if (response.error.status === 'serverMaintenance.') {
                  this._serverMaintenanceModalService.showModal();
                }
                return throwError(response);
              default:
                this._connectionProblemToast.next(false);
                return of(response.result);
            }
          })
        )
      ),
      tap((res) => {
        // console.log('Invoke res', res);
      })
    );
  }
}
